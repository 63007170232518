namespace $.$$ {

	const { px, rem } = $mol_style_unit

	$mol_style_define( $hyoo_studio_value_number, {
		
		Self: {
			flex: {
				grow: 1,
			},
		},
		
		Numb: {
			flex: {
				grow: 1,
			},
			String: {
				boxShadow: 'none',
			},
		},

	} )

}
