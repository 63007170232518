namespace $.$$ {

	const { px, rem } = $mol_style_unit

	$mol_style_define( $hyoo_studio_value_object, {

		Over_prop_name: {
			alignSelf: 'center',
			color: $mol_theme.shade,
			padding: {
				left: $mol_gap.block,
			},
		},

	} )

}
