namespace $.$$ {

	const { px, rem } = $mol_style_unit

	$mol_style_define( $hyoo_studio_value, {
		
		flex: {
			grow: 1,
		},

	} )

}
